//import CIcon from '@coreui/icons-react';
import { useContext } from 'react'
//import { FaSitemap, FaHome, FaUsers, FaUserPlus } from 'react-icons/fa'
// import { GiSpellBook } from 'react-icons/gi'
// import { GoReport } from 'react-icons/go'
import { Authcontext } from '../views/components/Context/Context';
//import HomeIcon from '@material-ui/icons/Home';
const Navigation = () => {

  const { state, dispatch } = useContext(Authcontext);


  return state.userType === 'Teaching'? [ {
    _tag: 'CSidebarNavItem',
    name: 'Katbook (Teacher Login)',
    to: `/TeacherView/${state.url}`,

    // onClick: () => {
    //   if (state) {
    //     if (state.route === 'Katbook (Published)') {
    //       return 0
    //     }
    //     else {
    //       dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
    //     }
    //   }
    //   else {
    //     dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
    //   }
    // }
    // ,
    // txt: state.route === 'Katbook (Published)' ? state.route : null

  },]: !state.UserTypecode ? [
    {
      _tag: "CSidebarNavItem",
      name: "Dashboard",
      to: "/dashboard",
      icon: "cil-home",
    },
    // {
    //   _tag: "CSidebarNavItem",
    //   name: "Subscribe",
    //   route: "/Subscribe",
    //   to: "/Subscribe",
    //   icon:"cil-laptop",
    //   onClick: () => {
    //     if (state) {
    //       if (state.route === 'Subscribe') {
    //         return 0
    //       }
    //       else {
    //         dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
    //       }
    //     }
    //     else {
    //       dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
    //     }
    //   }
    //   ,
    //   txt: state.route === 'Subscribe' ? state.route : null
    // },
    {
      _tag: "CSidebarNavItem",
      name: "Administrator",
      route: "/Administrator",
      to: '/AdministratorTile',
      icon: "cil-user",
      onClick: () => {
        if (state) {
          if (state.route === 'Administrator') {
            return 0
          }
          else {
            dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
          }
        }
        else {
          dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
        }
      }
      ,
      txt: state.route === 'Administrator' ? state.route : null

      //icon: <p className="c-sidebar-nav-icon" />,
      // _children: [
      //   {
      //     _tag: "CSidebarNavDropdown",
      //     name: "Role Library",
      //     route: "/location",
      //     _children: [
      //       {
      //         _tag: "CSidebarNavItem",
      //         name: "Create Role",
      //         to: "/CreateRole",
      //       },
      //       {
      //         _tag: "CSidebarNavItem",
      //         name: "Role Menu Privilege",
      //         to: "/RoleMenu",
      //       },
      //       {
      //         _tag: "CSidebarNavItem",
      //         name: "User Menu Privilege",
      //         to: "/UserMenu",
      //       },
      //     ]

      //   },
      //   {
      //     _tag: "CSidebarNavItem",
      //     name: "Location Master",
      //     to: "/Location",
      //   },

      // ],

    },
    {
      _tag: "CSidebarNavItem",
      name: "Human Resource",
      route: "/HumanResourceTile",
      to: '/HumanResourceTile',
      icon: "cil-people",

      //icon: <FaUsers className="c-sidebar-nav-icon" />,
      // _children: [
      //   {
      //     _tag: "CSidebarNavItem",
      //     name: "Organization SetUp",
      //     to: "/OrganizationSetup",

      //   },
      //   {
      //     _tag: "CSidebarNavItem",
      //     name: "Employee Data",
      //     to: "/EmployeeData",
      //   },
      // ]

    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Katbook Initiation',
      to: '/katbookInitiation',
      icon: "cil-bookmark",

      
      //icon: <GiSpellBook  className="c-sidebar-nav-icon" />,
      // _children: [
      //   {
      //     _tag: 'CSidebarNavItem',
      //     name: 'Katbook Initiation',
      //     to: '/katbookInitiation',
      //   },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Content Development Activies',
      //   to: '/location',
      // },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Staff Mapping',
      //   to: '/StaffMapping',
      // },

      // ]
      onClick: () => {
        if (state) {
          if (state.route === 'Katbook Initiation') {
            return 0
          }
          else {
            dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
          }
        }
        else {
          dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
        }
      }
      ,
      txt: state.route === 'Katbook Initiation' ? state.route : null
    },
    // {
    //   _tag: "CSidebarNavDropdown",
    //   name: "Activities Master",
    //   route: "/base",
    //   //icon: <FaUsers className="c-sidebar-nav-icon" />,
    //   txt: state.route === 'Activities Master' ? state.route : null,
    //   _children: [
    //   ]
    // },
    {
      _tag: 'CSidebarNavItem',
      name: 'Activities Master',
      route: '/ContentPreparationTile',
      to: '/ContentPreparationTile',
      icon: "cil-file",
      onClick: () => {
        if (state) {
          if (state.route === 'Content Preparation Tile') {
            return 0
          }
          else {
            dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
          }
        }
        else {
          dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
        }
      }
      ,
      txt: state.route === 'Content Preparation Tile' ? state.route : null
      //icon: <FaUsers className="c-sidebar-nav-icon" />,

    },
    // {
    //   _tag: 'CSidebarNavItem',
    //   name: 'Planning and Scheduling',
    //   route: '/PlanningScheduleTile',
    //   to: '/PlanningScheduleTile',
    //   icon: "cil-file",
    //   onClick: () => {
    //     if (state) {
    //       if (state.route === 'Content Preparation Tile') {
    //         return 0
    //       }
    //       else {
    //         dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
    //       }
    //     }
    //     else {
    //       dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
    //     }
    //   }
    //   ,
    //   txt: state.route === 'Content Preparation Tile' ? state.route : null
    //   //icon: <FaUsers className="c-sidebar-nav-icon" />,

    // },
    {
      _tag: "CSidebarNavItem",
      name: "Planning and Scheduling",
      route: "/PlanningScheduleTile",
      to: '/PlanningScheduleTile',
      icon: "cil-pencil",
     // _children: [],
     onClick: () => {
      if (state) {
        if (state.route === 'PlanningScheduleTile') {
          return 0
        }
        else {
          dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
        }
      }
      else {
        dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
      }
    }
    ,
    txt: state.route === 'PlanningScheduleTile' ? state.route : null
    },
    {
      _tag: "CSidebarNavItem",
      name: "Diary",
      route: "/base",
      icon: "cil-file",
      to: '/Diary',
     // _children: [],
     onClick: () => {
      if (state) {
        if (state.route === 'Diary') {
          return 0
        }
        else {
          dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
        }
      }
      else {
        dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
      }
    }
    },
    {
      _tag: "CSidebarNavItem",
      name: "Draft Katbook Content",
      route: "/Draft",
      to: '/Draft',
      icon: "cil-task",
     // _children: [],
    },
    // {
    //   _tag: 'CSidebarNavDropdown',
    //   name: 'Katbook Creation',
    //   route: '/Katbook Creation',
    //   //icon: <FaUsers className="c-sidebar-nav-icon" />,
    //   _children: [
    //     {
    //       _tag: 'CSidebarNavItem',
    //       name: 'Create Text Book / Notes',
    //       to: '/content/create',
    //     },
    //     {
    //       _tag: 'CSidebarNavItem',
    //       name: 'Create Advanced Content',
    //       to: '/CreateLink',
    //     },
    //     {
    //       _tag: 'CSidebarNavItem',
    //       name: 'Create Name of The Assessment',
    //       to: '/NameofExercise',
    //     },
    //     {
    //       _tag: 'CSidebarNavItem',
    //       name: 'Create Question / Answer Bank',
    //       to: '/PostQuestionAndAnswer',
    //     },

    //   ]
    // },
    {
      _tag: 'CSidebarNavItem',
      name: 'Katbook Creation',
      route: '/KatbookCreation',
      to: '/KatbookCreation',
      icon: "cil-pencil",
      onClick: () => {
        if (state) {
          if (state.route === 'Katbook Creation') {
            return 0
          }
          else {
            dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
          }
        }
        else {
          dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
        }
      }
      ,
      txt: state.route === 'Katbook Creation' ? state.route : null
      //icon: <FaUsers className="c-sidebar-nav-icon" />,

    },

    // {
    //   _tag: 'CSidebarNavDropdown',
    //   name: 'Katbook ( Published)',
    //   route: '/KatBookView',
    //   //icon: <FaUsers className="c-sidebar-nav-icon" />,
    //   _children: [
    //     {
    //       _tag: 'CSidebarNavItem',
    //       name: 'KatBook Published',
    //       to: '/KatBookView',
    //     },
    //   ]
    // },
    {
      _tag: 'CSidebarNavItem',
      name: 'Katbook (Published)',
      to: '/KatBookView',
      icon: "cil-book",
//       style:{
// display:State.userCreds.userType === 'Teaching'? 'none':'',
//       },
      onClick: () => {
        if (state) {
          if (state.route === 'Katbook (Published)') {
            return 0
          }
          else {
            dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
          }
        }
        else {
          dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
        }
      }
      ,
      txt: state.route === 'Katbook (Published)' ? state.route : null

    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Embibe Subject Mapping',
      to: '/BookMapping',
      icon: "cil-book",
//       style:{
// display:State.userCreds.userType === 'Teaching'? 'none':'',
//       },
      onClick: () => {
        if (state) {
          if (state.route === 'Embibe Subject Mapping') {
            return 0
          }
          else {
            dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
          }
        }
        else {
          dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
        }
      }
      ,
      txt: state.route === 'Embibe Subject Mapping' ? state.route : null

    },

//     {
//       _tag: 'CSidebarNavItem',
//       name: 'Hybrid Offline',
//       to: '/KatbookHybrid',
//       icon: "cil-book",
// //       style:{
// // display:State.userCreds.userType === 'Teaching'? 'none':'',
// //       },
//       onClick: () => {
//         if (state) {
//           if (state.route === 'Katbook Creation') {
//             return 0
//           }
//           else {
//             dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
//           }
//         }
//         else {
//           dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
//         }
//       }
//       ,
//       txt: state.route === 'Katbook Creation' ? state.route : null

//     },
   
    // {
    //   _tag: "CSidebarNavItem",
    //   name: "Reports",
    //   route: "/base",
    //   icon: "cil-grid",
    //  // _children: [],
    // },
    // {
    //   _tag: 'CSidebarNavDivider',
    //   className: 'm-2'
    // },
  ]: [
    {
      _tag: "CSidebarNavItem",
      name: "Dashboard",
      to: "/dashboard",
      icon: "cil-home",
    },
    // {
    //   _tag: "CSidebarNavItem",
    //   name: "Subscribe",
    //   route: "/Subscribe",
    //   to: "/Subscribe",
    //   icon:"cil-laptop",
    //   onClick: () => {
    //     if (state) {
    //       if (state.route === 'Subscribe') {
    //         return 0
    //       }
    //       else {
    //         dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
    //       }
    //     }
    //     else {
    //       dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
    //     }
    //   }
    //   ,
    //   txt: state.route === 'Subscribe' ? state.route : null
    // },
    {
      _tag: "CSidebarNavItem",
      name: "Administrator",
      route: "/Administrator",
      to: '/AdministratorTile',
      icon: "cil-user",
      onClick: () => {
        if (state) {
          if (state.route === 'Administrator') {
            return 0
          }
          else {
            dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
          }
        }
        else {
          dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
        }
      }
      ,
      txt: state.route === 'Administrator' ? state.route : null

      //icon: <p className="c-sidebar-nav-icon" />,
      // _children: [
      //   {
      //     _tag: "CSidebarNavDropdown",
      //     name: "Role Library",
      //     route: "/location",
      //     _children: [
      //       {
      //         _tag: "CSidebarNavItem",
      //         name: "Create Role",
      //         to: "/CreateRole",
      //       },
      //       {
      //         _tag: "CSidebarNavItem",
      //         name: "Role Menu Privilege",
      //         to: "/RoleMenu",
      //       },
      //       {
      //         _tag: "CSidebarNavItem",
      //         name: "User Menu Privilege",
      //         to: "/UserMenu",
      //       },
      //     ]

      //   },
      //   {
      //     _tag: "CSidebarNavItem",
      //     name: "Location Master",
      //     to: "/Location",
      //   },

      // ],

    },
    {
      _tag: "CSidebarNavItem",
      name: "Human Resource",
      route: "/HumanResourceTile",
      to: '/HumanResourceTile',
      icon: "cil-people",

      //icon: <FaUsers className="c-sidebar-nav-icon" />,
      // _children: [
      //   {
      //     _tag: "CSidebarNavItem",
      //     name: "Organization SetUp",
      //     to: "/OrganizationSetup",

      //   },
      //   {
      //     _tag: "CSidebarNavItem",
      //     name: "Employee Data",
      //     to: "/EmployeeData",
      //   },
      // ]

    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Katbook Initiation',
      to: '/katbookInitiation',
      icon: "cil-bookmark",

      
      //icon: <GiSpellBook  className="c-sidebar-nav-icon" />,
      // _children: [
      //   {
      //     _tag: 'CSidebarNavItem',
      //     name: 'Katbook Initiation',
      //     to: '/katbookInitiation',
      //   },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Content Development Activies',
      //   to: '/location',
      // },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Staff Mapping',
      //   to: '/StaffMapping',
      // },

      // ]
      onClick: () => {
        if (state) {
          if (state.route === 'Katbook Initiation') {
            return 0
          }
          else {
            dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
          }
        }
        else {
          dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
        }
      }
      ,
      txt: state.route === 'Katbook Initiation' ? state.route : null
    },
    // {
    //   _tag: "CSidebarNavDropdown",
    //   name: "Activities Master",
    //   route: "/base",
    //   //icon: <FaUsers className="c-sidebar-nav-icon" />,
    //   txt: state.route === 'Activities Master' ? state.route : null,
    //   _children: [
    //   ]
    // },
    {
      _tag: 'CSidebarNavItem',
      name: 'Activities Master',
      route: '/ContentPreparationTile',
      to: '/ContentPreparationTile',
      icon: "cil-file",
      onClick: () => {
        if (state) {
          if (state.route === 'Content Preparation Tile') {
            return 0
          }
          else {
            dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
          }
        }
        else {
          dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
        }
      }
      ,
      txt: state.route === 'Content Preparation Tile' ? state.route : null
      //icon: <FaUsers className="c-sidebar-nav-icon" />,

    },
    
    {
      _tag: "CSidebarNavItem",
      name: "Staff Subject Allocation",
      route: "/base",
      icon: "cil-task",
      to:'/StaffIndex',
      onClick: () => {
        if (state) {
          if (state.route === 'Staff Subject Allocation') {
            return 0
          }
          else {
            dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
          }
        }
        else {
          dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
        }
      }
      ,
      txt: state.route === 'Katbook Initiation' ? state.route : null

     // _children: [],
    },
    // {
    //   _tag: 'CSidebarNavItem',
    //   name: 'Planning and Scheduling',
    //   route: '/PlanningScheduleTile',
    //   to: '/PlanningScheduleTile',
    //   icon: "cil-file",
    //   onClick: () => {
    //     if (state) {
    //       if (state.route === 'Content Preparation Tile') {
    //         return 0
    //       }
    //       else {
    //         dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
    //       }
    //     }
    //     else {
    //       dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
    //     }
    //   }
    //   ,
    //   txt: state.route === 'Content Preparation Tile' ? state.route : null
    //   //icon: <FaUsers className="c-sidebar-nav-icon" />,

    // },
    {
      _tag: "CSidebarNavItem",
      name: "Planning and Scheduling",
      route: "/PlanningScheduleTile",
      to: '/PlanningScheduleTile',
      icon: "cil-pencil",
     // _children: [],
     onClick: () => {
      if (state) {
        if (state.route === 'PlanningScheduleTile') {
          return 0
        }
        else {
          dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
        }
      }
      else {
        dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
      }
    }
    ,
    txt: state.route === 'PlanningScheduleTile' ? state.route : null
    },
    {
      _tag: "CSidebarNavItem",
      name: "Diary",
      route: "/base",
      icon: "cil-file",
      to: '/Diary',
     // _children: [],
     onClick: () => {
      if (state) {
        if (state.route === 'Diary') {
          return 0
        }
        else {
          dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
        }
      }
      else {
        dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
      }
    }
    },
    {
      _tag: "CSidebarNavItem",
      name: "Draft Katbook Approval",
      route: "/Approved/Draft",
      to:"/Approved/Draft",
      icon: "cil-file",
     // _children: [],
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Katbook Creation',
      route: '/KatbookCreation',
      to: '/KatbookCreation',
      icon: "cil-pencil",
      onClick: () => {
        if (state) {
          if (state.route === 'Katbook Creation') {
            return 0
          }
          else {
            dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
          }
        }
        else {
          dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
        }
      }
      ,
      txt: state.route === 'Katbook Creation' ? state.route : null
      //icon: <FaUsers className="c-sidebar-nav-icon" />,

    },

    // {
    //   _tag: 'CSidebarNavDropdown',
    //   name: 'Katbook ( Published)',
    //   route: '/KatBookView',
    //   //icon: <FaUsers className="c-sidebar-nav-icon" />,
    //   _children: [
    //     {
    //       _tag: 'CSidebarNavItem',
    //       name: 'KatBook Published',
    //       to: '/KatBookView',
    //     },
    //   ]
    // },
    {
      _tag: 'CSidebarNavItem',
      name: 'Katbook (Published)',
      to: '/KatBookView',
      icon: "cil-book",
//       style:{
// display:State.userCreds.userType === 'Teaching'? 'none':'',
//       },
      onClick: () => {
        if (state) {
          if (state.route === 'Katbook (Published)') {
            return 0
          }
          else {
            dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
          }
        }
        else {
          dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
        }
      }
      ,
      txt: state.route === 'Katbook (Published)' ? state.route : null

    },

    {
      _tag: 'CSidebarNavItem',
      name: 'Hybrid Offline',
      to: '/KatbookHybrid',
      icon: "cil-book",
//       style:{
// display:State.userCreds.userType === 'Teaching'? 'none':'',
//       },
      onClick: () => {
        if (state) {
          if (state.route === 'Katbook Creation') {
            return 0
          }
          else {
            dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
          }
        }
        else {
          dispatch({ type: 'KBC_ROUTES', route: '', subRoute: '' })
        }
      }
      ,
      txt: state.route === 'Katbook Creation' ? state.route : null

    },
   
    // {
    //   _tag: "CSidebarNavItem",
    //   name: "Reports",
    //   route: "/base",
    //   icon: "cil-grid",
    //  // _children: [],
    // },
    // {
    //   _tag: 'CSidebarNavDivider',
    //   className: 'm-2'
    // },
  ]

}

export default Navigation
