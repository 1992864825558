import React from 'react';
// import StaffIndex from './views/StaffMapping/StaffIndex';
// import BookCreation from './views/BookCreation/BookCreation';
// import { Authcontext } from './views/components/Context/Context';
// import KBView from './views/KBView/KBView';

const KatbookInstitation = React.lazy(() => import('./views/katbookInstitation/KatBookInstition'));
// const ContentCreate = React.lazy(() => import('./views/Content/CreateContent'));

const CreateRole = React.lazy(() => import('./views/RoleCreation/CreateRole'));
const RoleMenu = React.lazy(() => import('./views/RoleMenu/RoleMenu'));
const UserMenu = React.lazy(() => import('./views/UserMenu/UserMenu'));
// const CreateLink = React.lazy(() => import('./views/ContentLink/CreateLink'));
const Location = React.lazy(() => import('./views/Location/Location'));
const StaffMapping = React.lazy(() => import('./views/StaffMapping/staffMapping'));
// const Exercise = React.lazy(() => import('./views/Exercise/CreateExercise'));
// const SlefAssessment = R/eact.lazy(() => import('./views/SelfAssessment/SlefAssessment'));
const CreateUser = React.lazy(() => import('./views/CreateUser/CreateUser'));
const KatBookView = React.lazy(() => import('./views/KatBookView/KatBookView'));
// const OfficeType = React.lazy(() => import('./views/OfficeType/OfficeType'));
// const OfficeLocation = React.lazy(() => import('./views/OfficeLocation/OfficeLocation'));
// const RoleCreation = React.lazy(() => import('./views/role/RoleCreation'));
const CreateRole1 = React.lazy(() => import('./views/RoleCreation/CreateRole'));
// const RoleCopy = React.lazy(() => import('./views/RoleCopy/RoleCopy'));

const KatBookCreation = React.lazy(() => import('./views/BookCreation/BookCreation'));

const TeacherHandBook = React.lazy(() => import('./views/TeacherHandBook/Teacherhandbook'));

const HybridCreation = React.lazy(() => import('./views/HybridCreation/HybridCreation'));
// const EmployeeTile = React.lazy(() => import('./views/EmployeeTile/EmployeeTile'));
const OrganizationTile = React.lazy(() => import('./views/Organization/OrganizationTile'));
const EmployeeData = React.lazy(() => import('./views/EmployeeData/EmployeeData'));
const TabsList = React.lazy(() => import('./views/tabslist/TabsList'));
const HumanResourceTile = React.lazy(() => import('./views/HumanResourceTile/HumanResourceTile'));
const AdministratorTile = React.lazy(() => import('./views/AdministratorTile/AdministratorTile'));
const TypeOfBook = React.lazy(() => import('./views/TypeOfBook/TypeOfBook'));
const TypeInstitution = React.lazy(() => import('./views/TypeInstitution/TypeInstitution'));
const Book = React.lazy(() => import('./views/KBView/KBView'));
const AddUser = React.lazy(() => import('./views/AddUser/AddUser'))
const UserKey = React.lazy(() => import('./views/UserKeyGenerate/UserKeyGenerate'))
const Subscribe = React.lazy(() => import('./views/Subscribe/Subscribe'));
const TeacherView = React.lazy(() => import('./views/TeacherView/TeacherView'));
const ContentPreparation = React.lazy(() => import('./views/ContentPreparation/ContentPreparationActivities'));
const ContentUploading = React.lazy(() => import('./views/ContentPreparation/ContentUploadingActivities'));
const ContentPreparationTile = React.lazy(() => import('./views/ContentPreparation/ContentPreparationTile'));
const PlanningScheduleTile = React.lazy(() => import('./views/PlaningScheduling/PlaningSchedulingTile'));
const AssignPreparation = React.lazy(() => import('./views/PlaningScheduling/AssignPreparation'));
const ViewPreparation = React.lazy(() => import('./views/PlaningScheduling/ViewPreparation'));
const ReSchedulingContent = React.lazy(() => import('./views/PlaningScheduling/ReSchedulingContent'));
const ReScheduleUploading = React.lazy(() => import('./views/PlaningScheduling/ReScheduleUploading'));
const AssignUploading = React.lazy(() => import('./views/PlaningScheduling/AssignUploading'));
const ViewUploading = React.lazy(() => import('./views/PlaningScheduling/ViewUploading'));
const DiaryIndex = React.lazy(() => import('./views/Diary/DiaryIndex'));
const DiaryTiles = React.lazy(() => import('./views/Diary/DiaryTilesUpload'));
const HolidayMasterEmployee = React.lazy(() => import('./views/HolidayMaster/HolidayEmployee'));
const StaffIndex = React.lazy(() => import('./views/StaffMapping/StaffIndex'));
// const TodaysTask = React.lazy(() => import('./views/Diary/tileScreens/TodaysTask'))
const TaskStatusUpdate = React.lazy(() => import('./views/Diary/PreparationTile/TaskStatusUpdate'))
const TaskStatusUploadingUpdate = React.lazy(() => import('./views/Diary/PreparationTile/TaskStausUploadUpdate'))
const TaskforPublisher = React.lazy(() => import('./views/Diary/PreparationTile/PublisherTask'))
const UpdateBaseContent = React.lazy(() => import('./views/Content/UpdateContent'))
// const TaskReport = React.lazy(() => import('./views/Diary/tileScreens/TaskReport'))
// const AppraisalReport = React.lazy(() => import('./views/Diary/tileScreens/AppraisalReport'))
// const StatusReport =  React.lazy(() => import('./views/Diary/tileScreens/StatusReport'))
// const TodaySelfTask = React.lazy(() => import('./views/Diary/tileScreens/UploadChildSreensTodayTask/SelfTask'))
// const TodayTeamTask = React.lazy(() => import('./views/Diary/tileScreens/UploadChildSreensTodayTask/TeamTask'))
// const WeeklySelfTask = React.lazy(() => import('./views/Diary/tileScreens/uploadChildScreensWeeklyTask/SelfTask'))
// const WeeklyTeamTask = React.lazy(() => import('./views/Diary/tileScreens/uploadChildScreensWeeklyTask/TeamTask'))
// const MonthlySelfTask = React.lazy(() => import('./views/Diary/tileScreens/UploadChildScreensMonthlyTask/SelfTask'))
// const MonthlyTeamTask = React.lazy(() => import('./views/Diary/tileScreens/UploadChildScreensMonthlyTask/TeamTask'))

// const WeeklyTaskAR= React.lazy(() => import('./views/Diary/tileScreens/UploadChildScreensAR/WeeklyTask'))
// const MonthlyTaskAR = React.lazy(() => import('./views/Diary/tileScreens/UploadChildScreensAR/MonthlyTask'))
// const TodayTaskAR = React.lazy(() => import('./views/Diary/tileScreens/UploadChildScreensAR/TodaysTask'))
const HolidayMaster = React.lazy(() => import('./views/HolidayMaster/HolidayMaster'))
const Dashboard = React.lazy(()=> import('./views/katbookInstitation/KatBookInstition'))

// Preparation

const DiaryTilesPreperation = React.lazy(() => import('./views/Diary/DiaryTilesPrepare'));
const DiaryPrepTaskReport = React.lazy(() => import('./views/Diary/Preperation/TaskReport/TaskReport'));
const DiaryPrepAppkReport = React.lazy(() => import('./views/Diary/Preperation/AppraisalReport/AppReport'));
const DiaryPreStatusReport = React.lazy(() => import('./views/Diary/StatusReport'));
const DiaryUploadTaskReport = React.lazy(() => import('./views/Diary/Uploading/TaskReport/TaskReport'));
const DiaryUploadAppReport = React.lazy(() => import('./views/Diary/Uploading/AppraisalReport/AppReport'));
const DiaryUploadStatusReport = React.lazy(() => import('./views/Diary/StatusReportUploading'));
const DiaryUploadEmployeeReport = React.lazy(() => import('./views/Diary/Uploading/EmployeeReport'));

const PrepTaskReportMonthly = React.lazy(() => import('./views/Diary/Preperation/TaskReport/MonthlyTask'));
const PrepTaskReportMonthlySelfTask = React.lazy(() => import('./views/Diary/Preperation/TaskReport/MonthlySelfTask'));
const PrepTaskReportMonthlyTeamTask = React.lazy(() => import('./views/Diary/Preperation/TaskReport/MonthlyTeamTask'))

const PrepTaskReportWeeklySelfTask = React.lazy(() => import('./views/Diary/Preperation/TaskReport/WeeklySelfTask'));
const PrepTaskReportWeeklyTeamTask = React.lazy(() => import('./views/Diary/Preperation/TaskReport/WeeklyTeamTask'))
const PrepTaskReportWeekly = React.lazy(() => import('./views/Diary/Preperation/TaskReport/WeeklyReport'));

const PrepTaskReportToday = React.lazy(() => import('./views/Diary/Preperation/TaskReport/TodaysReport'));
const PrepTaskReportTodaySelfTask = React.lazy(() => import('./views/Diary/Preperation/TaskReport/TodaysReportSelfTask'));
const PrepTaskReportTodayTeamTask = React.lazy(() => import('./views/Diary/Preperation/TaskReport/TodaysReportTeamTask'));


const PrepAppReportMonthly = React.lazy(() => import('./views/Diary/Preperation/AppraisalReport/MonthlyTask'));
const PrepAppReportMonthlySelfTask = React.lazy(() => import('./views/Diary/Preperation/AppraisalReport/MonthlySelfTask'));
const PrepAppReportMonthlyTeamTask = React.lazy(() => import('./views/Diary/Preperation/AppraisalReport/MonthlyTeamTask'));


const PrepAppReportWeekly = React.lazy(() => import('./views/Diary/Preperation/AppraisalReport/WeeklyReport'));
const PrepAppReportWeeklySelfTask = React.lazy(() => import('./views/Diary/Preperation/AppraisalReport/WeeklyReportSelfTask'));
const PrepAppReportWeeklyTeamTask = React.lazy(() => import('./views/Diary/Preperation/AppraisalReport/WeeklyReportTeamTask'));


const PrepAppReportToday = React.lazy(() => import('./views/Diary/Preperation/AppraisalReport/TodaysReport'));
const PrepAppReportTodaySelf = React.lazy(() => import('./views/Diary/Preperation/AppraisalReport/TodaysReportSelfTask'));
const PrepAppReportTodayTeam = React.lazy(() => import('./views/Diary/Preperation/AppraisalReport/TodaysReportTeamTask'));

const UploadTaskReportMonthly = React.lazy(() => import('./views/Diary/Uploading/TaskReport/MonthlyTask'));
const UploadTeamTasktMonthly = React.lazy(() => import('./views/Diary/Uploading/TaskReport/MonthlyTeamTask'));
const UploadSelfTasktMonthly = React.lazy(() => import('./views/Diary/Uploading/TaskReport/MonthlySelfTask'));


const UploadTaskReportWeekly = React.lazy(() => import('./views/Diary/Uploading/TaskReport/WeeklyReport'));
const UploadTeamTasktWeekly = React.lazy(() => import('./views/Diary/Uploading/TaskReport/WeeklyTeamTask'));
const UploadSelfTasktWeekly = React.lazy(() => import('./views/Diary/Uploading/TaskReport/WeeklySelfTask'));

const UploadTaskReportToday = React.lazy(() => import('./views/Diary/Uploading/TaskReport/TodaysReport'));
const UploadTaskReportTodaySelfTask = React.lazy(() => import('./views/Diary/Uploading/TaskReport/TodayReportSelfTask'));
const UploadTaskReportTodayTeamTask = React.lazy(() => import('./views/Diary/Uploading/TaskReport/TodayReportTeamTask'));

const UploadAppReportMonthly = React.lazy(() => import('./views/Diary/Uploading/AppraisalReport/MonthlyTask'));
const UploadAppReportMonthlySelfTask = React.lazy(() => import('./views/Diary/Uploading/AppraisalReport/MonthlySelfTask'));
const UploadAppReportMonthlyTeamTask = React.lazy(() => import('./views/Diary/Uploading/AppraisalReport/MonthlyTeamTask'));

const UploadAppReportWeekly = React.lazy(() => import('./views/Diary/Uploading/AppraisalReport/WeeklyReport'));
const UploadAppReportWeeklySelfTask = React.lazy(() => import('./views/Diary/Uploading/AppraisalReport/WeeklyReportSelfTask'));
const UploadAppReportWeeklyTeamTask = React.lazy(() => import('./views/Diary/Uploading/AppraisalReport/WeeklyReportTeamTask'));

const UploadAppReportToday=  React.lazy(()=> import('./views/Diary/Uploading/AppraisalReport/TodaysReport'));
const UploadAppReportTodaySelf =   React.lazy(()=> import('./views/Diary/Uploading/AppraisalReport/TodaysReportSelfTask'));
const UploadAppReportTodayTeam =   React.lazy(()=> import('./views/Diary/Uploading/AppraisalReport/TodaysReportTeamTask'));

// Draft Content
const DraftContent = React.lazy(()=> import('./views/DraftKatbookContent/DraftKatbookContentTile'));
// const DraftContentUpload = React.lazy(()=> import('./views/DraftKatbookContent/UploadDraftContent'));
const ReworkContentUpload = React.lazy(()=> import('./views/DraftKatbookContent/ReworkDraftContent'));
// const AaprovedContentUpload = React.lazy(()=> import('./views/DraftKatbookContent/ApprovedDraftContent'));
//Approved Draft Content
const ApprovedDraftContentTile = React.lazy(()=> import('./views/DraftKatbookContent/AdminApprovelDraftContent/AdminDraftTile'));
const ApprovedDraftContent = React.lazy(()=> import('./views/DraftKatbookContent/ApprovedDraftContent/ApprovedDraftContent'));
const ViewApprovedDraftContent = React.lazy(()=> import('./views/DraftKatbookContent/ApprovedDraftContent/ViewApprovedDraftContent'));
const ViewCorrectionDraftContent = React.lazy(()=> import('./views/DraftKatbookContent/ApprovedDraftContent/ViewApprovedCorrectionContent'));

const DraftUpload = React.lazy(()=> import('./views/DraftKatbookContent/StaffApprovedDraftContent/UploadDraft'));
const ApprovelDraft = React.lazy(()=>import('./views/DraftKatbookContent/AdminApprovelDraftContent/ApprovelDraft'));
const BookListForST =   React.lazy(()=> import('./views/KBView/BookListForST.js'));
const LinkContentView = React.lazy(()=>import('./views/ContentLink/ContentLinkView'));

const EmbBookMapping = React.lazy(() => import('./views/BookMapping/BookMapping'));

const routes = [
  // Dashboard

  { path: '/', exact: true, name: 'Home', component: Dashboard, },

  //KatBook
  { path: '/katbookInitiation', name: 'Katbook Initiation', component: KatbookInstitation, },
  // { path: '/content/create', name: 'Katbook Creation / Create Text Book & Notes', component: ContentCreate },
  { path: '/CreateRole', exact: true, name: 'Administrator / Role Library / Create Role', component: CreateRole },
  { path: '/RoleMenu', exact: true, name: 'Administrator / Role Library / Role Menu Privilege', component: RoleMenu },
  { path: '/UserMenu', exact: true, name: 'Administrator / Role Library / User Menu Privilege', component: UserMenu },
  // { path: '/NameofExercise', exact: true, name: 'Katbook Creation / Create Name of The Assessment', component: Exercise },
  // { path: '/PostQuestionAndAnswer', exact: true, name: 'Katbook Creation / Create Question & Answer Bank', component: SlefAssessment },
  { path: '/KatbookCreation', exact: true, name: 'Katbook Creation ', component: KatBookCreation },

  { path: '/TeacherHandbook', exact: true, name: 'Teacher Handbook', component: TeacherHandBook },
  

  // { path: '/HybridCreation', exact: true, name: 'Hybrid Creation ', component: CreateUnitZip },

  { path: '/CreateUser', exact: true, name: 'Add User', component: CreateUser },
  // { path: '/CreateLink', exact: true, name: 'Katbook Creation / Create Advanced Content', component: CreateLink },
  // { path: '/exercise', exact: true, name: 'Role Menu', component: Exercise},
  { path: '/Location', exact: true, name: 'Administrator / Location Master', component: Location },
  { path: '/StaffMapping', exact: true, name: 'Staff Mapping', component: StaffMapping },
  // { path: '/exercise', exact: true, name: 'Role Menu', component: Exercise},
  // { path: '/selfAssessment', exact: true, name: 'Role Menu', component: SlefAssessment},
  { path: '/KatBookView', exact: true, name: 'Katbook Creation / Katbook (Published)', component: KatBookView },
  // { path: '/OfficeType', exact: true, name: 'Type of Office', component: OfficeType },
  // { path: '/OfficeLocation', exact: true, name: 'Office Location', component: OfficeLocation },
  // { path: '/RoleCreation', exact: true, name: 'Role Creation', component: RoleCreation },
  { path: '/CreateRole1', exact: true, name: 'Creat Role', component: CreateRole1 },
  // { path: '/RoleCopy', exact: true, name: 'Creat Role', component: RoleCopy },


  { path: '/KatBookView', exact: true, name: 'Katbook Creation / Katbook (Published)', component: KatBookView },
  // { path: '/EmployeeTile', exact: true, name: 'Employee Data', component: EmployeeTile },
  { path: '/OrganizationSetup', exact: true, name: 'Employee Data', component: OrganizationTile },

  { path: '/EmployeeData', exact: true, name: 'Employee Data', component: EmployeeData },
  { path: '/EmployeeData/TabList', exact: true, name: 'Tabs List', component: TabsList },
  { path: '/BookView/:bookId/:book/:sId', exact: true, name: 'Book View', component: Book },
  { path: '/kampus/BookView/:bookId/:book/:sId/:refStandardID/:refSubjectID/:refInstID/:refMediumID', exact: true, name: 'Kampus Book View', component: Book },
  { path: '/kampus/BookView/:bookId/:book/:sId/:refStandardID/:refSubjectID/:refInstID', exact: true, name: 'Kampus Book View', component: Book },
  // {path:'/BookView/Content/:company/:country/:inst/:book/:obj/:crumb', exact: true, name: 'Book View Content', component:Book},
  { path: '/kampus/BookView/:bookId/:book/:sId/:refStandardID/:refSubjectID/:refInstID', exact: true, name: 'Kampus Book View', component: Book },
  { path: '/HumanResourceTile', exact: true, name: 'Human Resource', component: HumanResourceTile },
  { path: '/AdministratorTile', exact: true, name: 'Administrator', component: AdministratorTile },
  { path: '/TypeOfBook', exact: true, name: 'Type Of Book', component: TypeOfBook },
  { path: '/TypeInstitution', exact: true, name: 'Type Institution', component: TypeInstitution },

  { path: '/AddUser', exact: true, name: 'Add User', component: AddUser },

  { path: '/UserKey', exact: true, name: 'User Key', component: UserKey },
  { path: '/Subscribe', exact: true, name: 'Subscribe', component: Subscribe },

  { path: '/TeacherView/:teacherCreds', exact: true, name: 'Teacher View / Standards', component: TeacherView },
  //  {path:'/TeacherView/:teacherCreds', exact:true,name:'Teacher View / Standards',component:TeacherView},
  { path: '/ContentPreparation', exact: true, name: 'Content Preparation Activities ', component: ContentPreparation },
  { path: '/ContentUploading', exact: true, name: 'Content Uploading Activities ', component: ContentUploading },
  { path: '/ContentPreparationTile', exact: true, name: 'Activities Master ', component: ContentPreparationTile },
  { path: '/PlanningScheduleTile', exact: true, name: 'Planning and Scheduling ', component: PlanningScheduleTile },
  { path: '/AssignPreparation', exact: true, name: 'Planning and Scheduling ', component: AssignPreparation },
  { path: '/ViewPreparation', exact: true, name: 'Planning and Scheduling ', component: ViewPreparation },
  { path: '/ReSchedulingContent', exact: true, name: 'Planning and Scheduling ', component: ReSchedulingContent },
  { path: '/ReScheduleUploading', exact: true, name: 'Planning and Scheduling ', component: ReScheduleUploading },


  { path: '/Diary/ContentPreperationActivities/TaskStatusUpdate', exact: true, name: 'Task Status Update ', component: TaskStatusUpdate },
  { path: '/Diary/ContentUploadActivities/TaskStatusUpdate', exact: true, name: 'Task Status Update of Content Upload', component: TaskStatusUploadingUpdate },
  { path: '/Diary/ContentUploadActivities/MyTask', exact: true, name: 'My Task', component: TaskforPublisher },
  { path: '/Diary/ContentUploadActivities/TextbookContent/:sessionId', exact: true, name: 'Create / Update TextBook', component: UpdateBaseContent },
  

  { path: '/AssignUploading', exact: true, name: 'Planning and Scheduling ', component: AssignUploading },
  { path: '/ViewUploading', exact: true, name: 'Planning and Scheduling ', component: ViewUploading },
  { path: '/StaffIndex', exact: true, name: 'Staff Subject Allocation', component: StaffIndex },
  { path: '/Diary', exact: true, name: 'Diary', component: DiaryIndex },
  { path: '/Diary/ContentUploadActivities', exact: true, name: 'Content Publishing Activities', component: DiaryTiles },
  { path: '/Diary/ContentPreperationActivities', exact: true, name: 'Content Preparation Activities', component: DiaryTilesPreperation },

  { path: '/HumanResourceTile/HolidayMAster', exact: true, name: 'Holiday Master', component: HolidayMaster },
  { path: '/HumanResourceTile/HolidayMAsterEmployee', exact: true, name: 'Employee Based Holiday Setup', component: HolidayMasterEmployee },
  { path: '/Diary/ContentPreperationActivities/TaskReport', exact: true, name: 'Task Report', component: DiaryPrepTaskReport },
  { path: '/Diary/ContentPreperationActivities/APPReport', exact: true, name: 'Appraisal Report', component: DiaryPrepAppkReport },
  { path: '/Diary/ContentUploadActivities/TaskReport', exact: true, name: 'Task Report', component: DiaryUploadTaskReport },
  { path: '/Diary/ContentUploadActivities/APPReport', exact: true, name: 'Appraisal Report', component: DiaryUploadAppReport },
  { path: '/Diary/ContentUploadActivities/EmployeeReport', exact: true, name: 'Employee Report', component: DiaryUploadEmployeeReport },
  

  { path: '/Diary/ContentPreperationActivities/TaskReport/MonthlyReport', exact: true, name: 'Monthly Report', component: PrepTaskReportMonthly },
  { path: '/Diary/ContentPreperationActivities/TaskReport/MonthlyReport/SelfTask', exact: true, name: 'Self Task', component: PrepTaskReportMonthlySelfTask },
  { path: '/Diary/ContentPreperationActivities/TaskReport/MonthlyReport/TeamTask', exact: true, name: 'Team Task', component: PrepTaskReportMonthlyTeamTask },


  { path: '/Diary/ContentPreperationActivities/TaskReport/WeeklyReport', exact: true, name: 'Weekly Report', component: PrepTaskReportWeekly },
  { path: '/Diary/ContentPreperationActivities/TaskReport/WeeklyReport/SelfTask', exact: true, name: 'Self Task', component: PrepTaskReportWeeklySelfTask },
  { path: '/Diary/ContentPreperationActivities/TaskReport/WeeklyReport/TeamTask', exact: true, name: 'Team Task', component: PrepTaskReportWeeklyTeamTask },

  { path: '/Diary/ContentPreperationActivities/TaskReport/TodaysReport', exact: true, name: 'Todays Report', component: PrepTaskReportToday },
  { path: '/Diary/ContentPreperationActivities/TaskReport/TodaysReport/SelfTask', exact: true, name: 'Self Task', component: PrepTaskReportTodaySelfTask },
  { path: '/Diary/ContentPreperationActivities/TaskReport/TodaysReport/TeamTask', exact: true, name: 'Team Task', component: PrepTaskReportTodayTeamTask },


  { path: '/Diary/ContentPreperationActivities/APPReport/MonthlyReport', exact: true, name: 'Monthly Report', component: PrepAppReportMonthly },
  { path: '/Diary/ContentPreperationActivities/APPReport/MonthlyReport/SelfTask', exact: true, name: 'Self Task', component: PrepAppReportMonthlySelfTask },
  { path: '/Diary/ContentPreperationActivities/APPReport/MonthlyReport/TeamTask', exact: true, name: 'Team Task', component: PrepAppReportMonthlyTeamTask },

  { path: '/Diary/ContentPreperationActivities/APPReport/WeeklyReport', exact: true, name: 'Weekly Report', component: PrepAppReportWeekly },
  { path: '/Diary/ContentPreperationActivities/APPReport/WeeklyReport/SelfTask', exact: true, name: 'Self Task', component: PrepAppReportWeeklySelfTask },
  { path: '/Diary/ContentPreperationActivities/APPReport/WeeklyReport/TeamTask', exact: true, name: 'Team Task', component: PrepAppReportWeeklyTeamTask },


  { path: '/Diary/ContentPreperationActivities/APPReport/TodaysReport', exact: true, name: 'Todays Report', component: PrepAppReportToday },
  { path: '/Diary/ContentPreperationActivities/APPReport/TodaysReport/SelfTask', exact: true, name: 'Self Task', component: PrepAppReportTodaySelf },
  { path: '/Diary/ContentPreperationActivities/APPReport/TodaysReport/TeamTask', exact: true, name: 'Team Task', component: PrepAppReportTodayTeam },

  { path: '/Diary/ContentPreperationActivities/StatusReport', exact: true, name: 'Status Report', component: DiaryPreStatusReport },
  // Uploading Route

  { path: '/Diary/ContentUploadActivities/TaskReport/MonthlyReport', exact: true, name: 'Monthly Report', component: UploadTaskReportMonthly },
  { path: '/Diary/ContentUploadActivities/TaskReport/TeamTask', exact: true, name: 'Team Task', component: UploadTeamTasktMonthly },
  { path: '/Diary/ContentUploadActivities/TaskReport/SelfTask', exact: true, name: 'Self Task', component: UploadSelfTasktMonthly },


  { path: '/Diary/ContentUploadActivities/TaskReport/WeeklyReport', exact: true, name: 'Weekly Report', component: UploadTaskReportWeekly },
  { path: '/Diary/ContentUploadActivities/TaskReport/WeeklyReport/TeamTask', exact: true, name: 'Team Task', component: UploadTeamTasktWeekly },
  { path: '/Diary/ContentUploadActivities/TaskReport/WeeklyReport/SelfTask', exact: true, name: 'Self Task', component: UploadSelfTasktWeekly },

  { path: '/Diary/ContentUploadActivities/TaskReport/TodaysReport', exact: true, name: 'Todays Report', component: UploadTaskReportToday },
  { path: '/Diary/ContentUploadActivities/TaskReport/TodaysReport/SelfTask', exact: true, name: 'Self Task', component: UploadTaskReportTodaySelfTask },
  { path: '/Diary/ContentUploadActivities/TaskReport/TodaysReport/TeamTask', exact: true, name: 'Team Task', component: UploadTaskReportTodayTeamTask },


  { path: '/Diary/ContentUploadActivities/APPReport/MonthlyReport', exact: true, name: 'Monthly Report', component: UploadAppReportMonthly },
  { path: '/Diary/ContentUploadActivities/APPReport/MonthlyReport/SelfTask', exact: true, name: 'Self Task', component: UploadAppReportMonthlySelfTask },
  { path: '/Diary/ContentUploadActivities/APPReport/MonthlyReport/TeamTask', exact: true, name: 'Team Task', component: UploadAppReportMonthlyTeamTask },


  { path: '/Diary/ContentUploadActivities/APPReport/WeeklyReport', exact: true, name: 'Weekly Report', component: UploadAppReportWeekly },
  { path: '/Diary/ContentUploadActivities/APPReport/WeeklyReport/SelfTask', exact: true, name: 'Self Task', component: UploadAppReportWeeklySelfTask },
  { path: '/Diary/ContentUploadActivities/APPReport/WeeklyReport/TeamTask', exact: true, name: 'Team Task', component: UploadAppReportWeeklyTeamTask },


  { path: '/Diary/ContentUploadActivities/APPReport/TodaysReport', exact: true, name: 'Todays Report', component: UploadAppReportToday },
  { path: '/Diary/ContentUploadActivities/APPReport/TodaysReport/SelfTask', exact: true, name: 'Self Task', component: UploadAppReportTodaySelf },
  { path: '/Diary/ContentUploadActivities/APPReport/TodaysReport/TeamTask', exact: true, name: 'Team Task', component: UploadAppReportTodayTeam },

  { path: '/Diary/ContentUploadActivities/StatusReport', exact: true, name: 'Status Report', component: DiaryUploadStatusReport },
  { path: '/student/bookList/:Creds', exact: true, name: 'Student Book List', component: BookListForST },

  //Draft Content 
  {path:'/Draft', exact:true,name:'Draft Katbbok Content',component:DraftContent},
  // {path:'/Draft/UploadContent', exact:true,name:'Upload Draft Katbook Content',component:DraftContentUpload},
  {path:'/Draft/Rework', exact:true,name:'Rework Draft Katbook Content',component:ReworkContentUpload},
  // {path:'/Draft/Approved', exact:true,name:'Rework Draft Katbook Content',component:AaprovedContentUpload},
  // {path:'/Approved/Draft', exact:true,name:'Approved Draft Katbook Content',component:ApprovedDraftContentTile},
  {path:'/Approved/ApprovedDraftContent', exact:true,name:'Approved Draft Katbook Content',component:ApprovedDraftContent},
  {path:'/Approved/ViewApprovedDraftContent', exact:true,name:'View Approved Content Katbook Content',component:ViewApprovedDraftContent},
  {path:'/Approved/ViewCorrectionDraftContent', exact:true,name:'View Correction Draft Katbook Content',component:ViewCorrectionDraftContent},

  {path:'/Draft/Upload', exact:true,name:'Upload Draft Katbook Content',component:DraftUpload},
  {path:'/Draft/Approvel', exact:true,name:'Rework Draft Katbook Content',component:ApprovelDraft},
  {path:'/Approved/Draft', exact:true,name:'Rework Draft Katbook Content',component:ApprovedDraftContentTile},
  { path: '/content/LinkView/:URL', exact: true, name: 'Content Link View', component: LinkContentView },

  
  // Hybrid Offline
  { path: '/KatbookHybrid', exact: true, name: 'Hybrid Creation', component: HybridCreation },


  { path: '/BookMapping', exact: true, name: 'Katbook / Book Mapping', component: EmbBookMapping },
];

export default routes;
